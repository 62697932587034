import React from 'react';
import type { GetServerSideProps } from 'next';
import Home from '../features/Home';

const cacheTime = process.env.CACHE_TIME;
const indexingHostname = 'www.menti.com';

export default function StartPage() {
  return <Home />;
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  ctx.res.setHeader(
    'Cache-Control',
    `public, max-age=${cacheTime}, stale-while-revalidate=${cacheTime}, stale-if-error=86400`,
  );

  // The index page is the only page that should be indexable by search engines
  // and only for the main hostname, i.e. only 'www.menti.com' and not 'menti.com'.
  if (
    indexingHostname === ctx.req.headers.host ||
    indexingHostname === ctx.req.headers['x-forwarded-host']
  )
    ctx.res.setHeader('X-Robots-Tag', 'all');

  return { props: {} };
};
