import * as React from 'react';
import type { DismissT } from '../Dismiss/Dismiss';
import { themeMap } from '../Themes';
import type { ThemeMapTypeT } from '../Themes';
import { Box, type BoxT } from '../Box';
import { Dismiss } from '../Dismiss/Dismiss';
import { Group, type GroupActionT, type ActionPatternT } from '../Group';

type LayerT = 'flat' | 'close' | 'far';

export interface PromptGenericT extends BoxT, DismissT, GroupActionT {
  layer?: LayerT | undefined;
  theme?: ThemeMapTypeT | undefined;
  autoFocus?: boolean;
  hasDismiss?: boolean;
  actionPattern?: ActionPatternT;
}

const boxLayerMap = {
  flat: undefined,
  close: '0 3px 15px rgba(0,0,0,0.2)',
  far: '0 6px 25px rgba(0,0,0,0.2)',
};

export const PromptGeneric = ({
  children,
  onDismiss,
  dismissLabel,
  hasDismiss = true,
  layer = 'flat',
  theme,
  actions,
  autoFocus,
  actionPattern = 'z',
  ...props
}: PromptGenericT) => {
  const content = (
    <Box
      display="block"
      width="auto"
      overflow="auto"
      bg="surface"
      p="space6"
      pr={onDismiss ? 'space14' : 'space6'}
      {...props}
      color="text"
      borderRadius="3xl"
      extend={() => ({
        boxShadow: boxLayerMap[layer],
      })}
    >
      {hasDismiss && (
        <Box position="absolute" top={0} right={0}>
          <Dismiss
            onDismiss={onDismiss}
            dismissLabel={dismissLabel}
            p="space6"
          />
        </Box>
      )}
      {children}
      <Group
        autoFocus={autoFocus}
        actions={actions}
        mt={['space2', 'space4']}
        actionPattern={actionPattern}
      />
    </Box>
  );
  // Conditional theme in order to not double the rendering when for example
  // Popover & Modal already has a theme due to the arrow coloring and backdrop.
  if (theme) {
    const T = themeMap[theme];
    return <T>{content}</T>;
  }
  return content;
};
