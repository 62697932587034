import * as React from 'react';
import { Banner, Link, Text } from '@mentimeter/ragnar-ui';
import { trackEvent } from '../../helpers/tracker';
import { useCookieConsent } from './CookieConsent';

export const CookieBanner = () => {
  const { cookiesEnabled, acceptCookies } = useCookieConsent();

  if (cookiesEnabled) return null;

  return (
    <Banner theme="dark" onDismiss={acceptCookies}>
      <Text textAlign="center">
        We use cookies to provide this service and improve your experience.{' '}
        <Link
          color="text"
          style={{ whiteSpace: 'nowrap' }}
          href="https://www.mentimeter.com/cookie-policy"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => trackEvent('link', 'clicked', 'Cookie policy')}
        >
          Learn more
        </Link>
      </Text>
    </Banner>
  );
};
