import * as React from 'react';
import { themeMap, type ThemeMapTypeT, Primary } from '../Themes';
import { Box, type BoxT } from '../Box';
import { Text } from '../Text';
import { Link, type LinkT } from '../Link';
import { Prompt, type PromptT } from '../Prompt';
import type { ActionPatternT } from '../Group';
export interface BannerT extends BoxT {
  children?: React.ReactNode;
  link?: LinkT;
  theme?: ThemeMapTypeT;
  title?: string | undefined;
  actionPattern?: ActionPatternT;
  actions?: PromptT['actions'];
  onDismiss?: PromptT['onDismiss'];
}

export const Banner = ({
  title,
  link,
  actions,
  onDismiss,
  actionPattern = 'c',
  theme,
  children,
  ...rest
}: BannerT) => {
  const content = (
    <Box
      alignItems="center"
      width={1}
      bg="bg"
      zIndex={9999}
      {...rest}
      borderRadius="2xl"
    >
      <Prompt
        bg="transparent"
        dismissLabel="Close Banner"
        onDismiss={onDismiss}
        width="100%"
        actions={actions}
        actionPattern={actionPattern}
      >
        <Box alignItems="center" mx={onDismiss ? 'space6' : 'space0'}>
          {title && (
            <Text
              fontWeight="regular"
              color="inherit"
              textAlign={actionPattern === 'c' ? 'center' : 'left'}
              fontSize={['87.5', '100']}
            >
              {title}
              {link && (
                <Link
                  ml="space3"
                  color="inherit"
                  fontSize="inherit"
                  {...link}
                />
              )}
            </Text>
          )}
          {children}
        </Box>
      </Prompt>
    </Box>
  );
  if (theme) {
    const T = themeMap[theme];
    return <T>{content}</T>;
  }
  return <Primary>{content}</Primary>;
};
