import * as React from 'react';
import { Text } from '../Text';
import { PromptGeneric } from './PromptGeneric';
import type { PromptGenericT } from './PromptGeneric';

export interface PromptT extends Omit<PromptGenericT, 'title'> {
  children: React.ReactNode;
  title?: React.ReactNode;
}

export const Prompt = ({
  title,
  children,
  actions,
  onDismiss,
  dismissLabel,
  layer,
  theme,
  ...props
}: PromptT) => {
  return (
    <PromptGeneric
      theme={theme}
      onDismiss={onDismiss}
      dismissLabel={dismissLabel}
      layer={layer}
      actions={actions}
      {...props}
    >
      {title && typeof title === 'string' ? (
        <Text
          as="h1"
          mb="space4"
          fontSize="125"
          pr={onDismiss ? 'space8' : 'space0'}
          lineHeight="heading"
          color="text"
          fontWeight="semiBold"
        >
          {title}
        </Text>
      ) : (
        title
      )}

      {typeof children === 'string' ? (
        <Text as="p" mb="space4" lineHeight="normal">
          {children}
        </Text>
      ) : (
        children
      )}
    </PromptGeneric>
  );
};
